// top
@keyframes start-border01 {
  0% {
    height: 0;
  }
  50% {
    height: 100vh;
  }
  100% {
    height: 100vh;
  }
}
@keyframes start-border02 {
  0% {
    width: 0;
  }
  50% {
    width: 0;
  }
  100% {
    width: 100vw;
  }
}
// floor
@keyframes floor_btn {
  0% {
    transform: scale(.95);
    opacity: 1;
  }
  90% {
    opacity: .1;
  }
  to {
    transform: scale(1.35);
    opacity: 0;
  }
}
