/*common*/
html, body {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    font-family: $font-sans_serif;
    color: $text-color;
    background:$bg-color;
    letter-spacing:0.05em;
    line-height:1.5;
    overflow-x:hidden;

    overflow-wrap: break-word;
    @include mq(mpc) {
        overflow-x:auto;
    }
}

.flx{
    display:flex;
    &._sb{
        justify-content:space-between;
    }
    &._fe{
        justify-content:flex-end;
    }
}

.font{
    &-g{
        font-family:$font-sans_serif;
    }
    &-m{
        font-family:$font-min;
    }

}

.inner {
    width: 100%;
    padding: 0 6%;
    @include mq(pc) {
        margin:0 auto;
        max-width: $main-width;
    }
}

/* btn
-------------------------------------*/
.btn{
    border-radius:80px;
    padding:20px;
    display:block;
    margin:0 auto;
    cursor: pointer;
    width:100%;
    text-align:center;
    text-decoration:none;
    font-weight:500;
    color:$white;
    .flx._sb &{
        @include mq(pc) {
            width:46%;margin:0;
        }
        @include mq {
            width:100%;margin:0 0 10px;
        }
    }

    &-wrap{
        padding:30px 0;
        text-align:center;
        @include mq(pc) {
            padding:50px 0;
        }
        @include mq {
            &.flx{display:block;}
        }

    }

    &._primary{
        background:#000;
        color:$white !important;
    }
    &._noclick{
        color: #515151;
        background: #a1a1a1;
    }
    &._return{
        color:$white !important;
        background: #adadad;
        padding:10px 20px;
    }
    &._zip{
        color:$white !important;
        background: #000;
        padding: 10px 0;
        display:inline-block;
        
        @include mq {
            width:110px;
            font-size: 11px;
        }
        @include mq(pc) {
            width:150px;
            font-size: 13px;
            margin-left:10px;
        }
    }
}

/* input
-------------------------------------*/
input:not([type="checkbox"]):not([type="radio"]):not([type="file"]):not([type="hidden"]):not([type="submit"]):not([type="button"]),select,textarea{
    @include radius;
    padding:10px 10px;
    width:100%;
    border:1px solid $bg-color01;
    font-family: $font-sans_serif;
    background:$white;


    &:placeholder{
        color:$text-color03;
    }
    &.error{
        border:1px solid $red;
        background:$red_bg;
    }


    &.txtbox_type1 {
        @include mq(pc) {
            width: 50%;
        }
    }

    &.txtbox_type2 {
        width: 35%;
    }

    &.txtbox_type3 {
        min-width: 270px;
        width: 80%;
    }

}
input[type="submit"],input[type="button"]{
    font-weight:500;
    font-family: $font-sans_serif;
}

select{
    width:auto;
    option{
        padding:10px;
        &::selected{
            background:#ff0000;
        }
    }
    ._multi &{
        padding:10px;
        border:1px solid $bg-color01;
        @include radius;
        background:$white;
    }
    .dl-box &{
        padding:10px;
        border:1px solid $gray01;
        @include radius;
        background:$white;
    }
}






img{
    max-width: 100%;
    height: auto;

}
/**/
.blc{
    &-domain{
        margin-bottom:40px;
        text-align:center;
        font-size:14px;
        .tbl-form &{
            margin:20px 0 0;
        }

        dt{
            

        }
        dd{
            ul{
                margin:15px 0 0;
                text-align:left;
                list-style:disc;
                margin-left:30px;
            }
            >p{
                margin:10px 0 0;
                padding:10px;
                border:1px solid $gray01;
                background:$white;
                border-radius:5px;
            }
        }
    }
}

/* form
-------------------------------------*/
.step{
    &-wrap{
        display:flex;
        justify-content:space-between;
        align-itmes:center;
        margin-bottom:20px;
        li{
            width: 33.333%;
            border-top: 1px solid #7f7f7f;
            border-bottom: 1px solid #7f7f7f;
            border-right: 1px solid #7f7f7f;
            color: #4c4c4c;
            font-weight: bold;
            padding: 10px 0 8px;
            background: #ffffff;
            text-align:center;
            &:first-child{
                border-left: 1px solid #7f7f7f;
            }
            &.finish{
                background: #f2f2f2;
            }
            >span{
                display:block;
            
            }
        }
    }
}

.tbl{

    &-form{
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #7f7f7f;
        @include mq(pc) {
            margin-bottom: 20px;
        }
        @include mq() {
            border: none;
            font-size:14px;
        }
        tr{
        }
        th,td{
            padding: 15px;
            vertical-align: middle;
            @include mq() {
                display:block;
                width:100%;
            }
        }
        th{
            text-align: left;
            padding-right: 60px;
            background-color: #f2f2f2;
            position: relative;
            @include mq(pc) {
                width: 30%;
                border: 1px solid #7f7f7f;
            }
            span.required {
                color: #fff;
                font-size: 12px;
                font-weight: bold;
                padding: 5px 10px;
                background: #e02323;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            }
            
        }
        td{
            @include mq(pc) {
                width: 70%;
                border: 1px solid #7f7f7f;
            }
            @include mq() {
            }
            >div:not(:first-child){
                margin-top:10px;
            }
        }



    }
}
.txt{
    margin-bottom:10px;
    &-note{
        display:block;
        font-size:14px;
        color:$text-color02;
        margin-top:10px;
        @include mq() {
            font-size:12px;
        }
    }
}
/*error*/
.error_txt {
    color: $red;
    font-size: 14px;
    font-weight: 500;
    img{
        @include mq() {
            width:20px;
            display:inline-block;
        }
        
    }

}
.bg_txtbox_error {
    background-color: $red_bg;
}
.content_bold_txt {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.error_txt_fb {
    font-size: 22px;
    font-weight: bold;
    color: #F00;
    text-align: center;
    @include mq() {
        .error_txt_fb {
            font-size: 18px;
        }
    }

}


/* align
-------------------------------------*/
.align{
    &-r{
        text-align:right;
    }
    &-c{
        text-align:center;
    }
    &-l{
        text-align:left;
    }
}

/*old*/
.text-center{
    text-align:center;
}


/* margin
-------------------------------------*/
.mt {
  &0 {
    margin-top: 0 !important;
  }
  &10 {
    margin-top: 10px !important;
  }
  &15 {
    margin-top: 15px !important;
  }
  &20 {
    margin-top: 20px !important;
  }
  &30 {
    margin-top: 30px !important;
  }
  &40 {
    margin-top: 40px !important;
  }
  &50 {
    margin-top: 50px !important;
  }
  &60 {
    margin-top: 60px !important;
  }
}
.mb {
  &10 {
    margin-bottom: 10px !important;
  }
  &20 {
    margin-bottom: 20px !important;
  }
  &30 {
    margin-bottom: 30px !important;
  }
  &40 {
    margin-bottom: 40px !important;
  }
  &50 {
    margin-bottom: 50px !important;
  }
  &60 {
    margin-bottom: 60px !important;
  }
}
a.nolink {
  pointer-events: none;
}


/*old*/
.h-inner,.f-inner {
    text-align: center;
    width: 100%;
    margin: 0 auto;
}
.frm_contact{
margin:30px 0;
background:$white;
}
.silver_txt {
    color: #7f7f7f;
}