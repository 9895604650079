// Base
@import 'base/base';
@import 'base/fontawesome';
// Mixin
@import 'mixin/mixin';
@import 'mixin/z-index';
@import 'mixin/animation';
// Fonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&family=Noto+Serif+JP:wght@300;400;500;600;700&display=swap');
// Plubin
@import 'plugin/slick';
@import 'plugin/modaal';
@import 'plugin/scroll-hint';
// Layout
//----------------------------------------------
// ----------
// common
// ----------

// ------------------------------
// 下層 共通
// ------------------------------
@import 'page/parts';
@import 'page/game';
@import 'page/modal';
// ------------------------------
// 下層
// ------------------------------