$z-index: (".btn-spmenu" ".header" ".sp-menu" "main");

@function z-index($name, $list: $z-index) {
  @return abs(index($list, $name) - length($list) - 1);
}

@each $name in $z-index {
  #{$name} {
    z-index: z-index($name);
  }
}

main {
  position: relative;
}
