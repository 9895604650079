@import "../mixin/mixin";



/*  link 
-----------------------------------------------------------------------------*/
a {
  transition-property: background-color, opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease;
}
a,
a:visited,
a:hover {
  color: $key-color02;
}

a img {
  border: 0;
}

.clearfix:after {
  height: 0;
  display: block;
  clear: both;
  content: " ";
}

@include mq(pc) {
  br.pc,
  img.pc {
    display: block;
  }
  br.sp,
  img.sp {
    display: none;
  }
  .pc {
    display: block;
  }
  .sp{
    display: none;
  }
}
@include mq(mpc) {
  br.pc,
  img.pc {
    display: block;
  }
  .pc {
    display: block;
  }
}
@include mq(lsp) {
  br.sp,
  img.sp {
    display: block;
  }
  br.pc,
  img.pc {
    display: none;
  }
  .sp{
    display: block;
  }
  .pc {
    display: block;
  }
}

/*  reset 
-----------------------------------------------------------------------------*/
/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
  box-sizing: border-box;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ul, ol {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

// フォーム部品の完全リセット用
// input {
//   -webkit-appearance: none; 
// }
// select {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
// }
// select::-ms-expand {
//   display: none;
// }

img {
  vertical-align: top;
  @include mq {
    width: 100%;
    height:auto;
  }
}

main {
  // IE11
  display: block;
}

[v-cloak] {
  display: none;
}