/* result use
-------------------------------------*/
#use_area {
    margin-top: 15px;

}

#use_area .use_btn span {
  font-size: 13px;
  font-weight: normal;
  display: block;
  margin-top: 3px;
}

#use_area .use_btn:hover {
  text-decoration: none;
}

.use_modal_wrap {
  display: none;
}



/**/
#use_modal_area {
  height: 85vh;
  color:$text-color;
  background: #fff;
  overflow: hidden;
}

.usemodal{
    &-blc{
        display:flex;
        flex-wrap:wrap;
        flex-direction:column;
        justify-content:center;
        align-items:stretch;
        height:85vh;

        &_img{
            height: calc((85vh - 50px)/2);
            background: #f9ede7;
            padding: 20px 15px;
            img{
                max-width: 70%;
                margin: 0 auto;
                display: block;
                max-height:100%;
            }
        }
        &_txt{
            height: calc((85vh - 50px)/2);
            overflow-y: auto;
            width: 100%;
            padding: 15px;
            >p{
                &:first-child{
                  color: #a00000;
                  font-size: 16px;
                  font-weight: bold;
                  margin-bottom: 15px;
                }
                &:nth-child(n+2) {
                  text-indent: -1em;
                  padding-left: 1em;
                }
            }



        }
        &_btn{
            display:flex;
            height:50px;
            margin-top: auto;

            > *{
              font-size:14px;
              width:50%;
            }

            #send_use {
              background: #af0000;
              color: #fff;
              cursor: pointer;
              border-radius: initial;
            }

            #cancel_btn {
              color: #000;
              background: #dedede;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            
        }
    }

}



#use_modal_area .modal_content {
    height: calc(100% - 50px);
    overflow-y: scroll;
}






#cboxContent { margin-top: 0; }

#cboxClose {
  display: none;
}