$breakpoints: (
  "pc": "print, screen and (min-width: 769px)",
  "mpc": "screen and (max-width: 1300px)",
  "spc": "screen and (max-width: 1000px)",
  "lsp": "screen and (max-width: 768px)",
  "msp": "screen and (max-width: 414px)",
  "ssp": "screen and (max-width: 320px)",
  "pcsh": "screen and (max-height: 800px)"
) !default;

@mixin mq($breakpoint: lsp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

$main-color: #00215B;
$key-color01: #FF6600;
$key-color02: #1C7CD5;
$text-color: #000;
$text-color02:#676767;
$text-color03:#A9A9A9;
$bg-color: #F4F5F6;
$bg-color01: #F2F2F2;
$btn-submit:#B2B2B2;
$btn-back:#285AC8;
$btn-del:#b2b2b2;
$border-color:#E5E5E5;

$white: #fff;
$red: #FF5D5D;
$red_bg:#FFE8E8;
$green: #669933;
$green02: #669933;
$gray01: #b2b2b2;
$gray02: #ddd;
$gray03: #888;
$admin-color: #00215B;
$available: #00C6A0;
$unavailable: #b2b2b2;



$key-color: #285AC8;
$color-bg01: #f9efb2;
$color-bg02: #fffdf1;
$color-bg03: #d6e3b3;
$color-bg04: #fffef6;
$color-border01:#cdc4b2;
$black01: #000;
$blue: #1D409D;
$color-yt: #cd201f;
$color-fb: #3b5998;
$color-tw: #55acee;
$color-ln: #00b900;
$info-color: #0F6EB5;
$pink: #FF0079;



$main-width: 1240px;
$pc-min-width: 1240px;
$pccont-max-width: 1000px;
$pc-header-height: 100px;
$sp-header-height: 60px;


$font-sans_serif: 'Noto Sans JP', sans-serif;
$font-min: 'Noto Serif JP', serif;


$font-sans_serif02: "游ゴシック Medium", "游ゴシック", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", 'Noto Sans JP', メイリオ, Meiryo, sans-serif;


$font-en: 'Marcellus SC', serif;


$font-min02: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho",'Noto Serif JP', serif;


$font-apple: 'Homemade Apple', cursive;

$font-bar: 'Barlow', sans-serif;

$font-num: 'Overpass', sans-serif;
$font-num2: 'Lato', sans-serif;

@mixin inner {
  width: $main-width;
  margin: 0 auto;
  @include mq(mpc) {
    width: 100%;
    min-width:$main-width;
    padding-left:20px;
    padding-right:20px;
  }
}

@mixin lh($fontSize, $height) {
  line-height: $height / $fontSize;
}
@mixin ls($tracking) {
  letter-spacing: #{$tracking / 1000}em;
}

@mixin transition($prop: all, $dur: 0.5, $func: ease, $delay: 0) {
  transition: $prop #{$dur}s $func #{$delay}s;
}

@mixin link_color($color) {
  &,
  &:visited,
  &:hover {
    color: $color;
  }
}

@mixin arrow($width, $bold, $color, $deg) {
  border-style: solid;
  border-width: 0 #{$bold}px #{$bold}px 0;
  content: "";
  display: inline-block;
  border-color: $color;
  @if ($deg == "left") {
    transform: rotate(135deg);
  } @else if ($deg == "top") {
    transform: rotate(225deg);
  } @else if ($deg == "right") {
    transform: rotate(-45deg);
  } @else if ($deg == "bottom") {
    transform: rotate(45deg);
  }
  height: #{$width}px;
  width: #{$width}px;
}

@mixin object-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}


@mixin abs-center{
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}
@mixin radius {
    border-radius:5px;
}