/* game css
-------------------------------------*/
/*card*/
.card_item {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin:30px 0;
        @include mq(pc) {
            
            margin:30px;
        }
        div{
            width: calc(100% / 3);
            margin: 0 0 15px;
            padding:0 5px;
            @include mq(pc) {
                padding:0 10px;
                width:  calc(100% / 5);
            }

        }
}

/*scratch*/
#scrach_card {
        margin:30px 0;
        text-align:center;
}